define('kursausschreibung/framework/date-helpers', ['exports', 'date-fns/parseISO', 'date-fns/format', 'date-fns/locale/de', 'date-fns/locale/fr', 'kursausschreibung/framework/translate'], function (exports, _parseISO, _format, _de, _fr, _translate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDate = formatDate;
  exports.isInSubscriptionRange = isInSubscriptionRange;
  exports.eventStarted = eventStarted;
  exports.eventEnded = eventEnded;
  exports.combineDate = combineDate;
  exports.removeMinutes = removeMinutes;
  exports.getDMY = getDMY;
  exports.getYMD = getYMD;
  exports.getDateTimeForIcs = getDateTimeForIcs;
  exports.dateGreaterNow = dateGreaterNow;


  // longDateFormats from moment.js
  const formats = {
    'de-CH': {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'dd.MM.yyyy',
      LL: 'EEEEEE, d. MMMM yyyy',
      LLL: 'EEEEEE, d. MMMM yyyy HH:mm',
      LLLL: 'EEEE, d. MMMM yyyy HH:mm'
    },

    'fr-CH': {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'dd.MM.yyyy',
      LL: 'EEEEEE, d MMMM yyyy',
      LLL: 'EEEEEE, d MMMM yyyy HH:mm',
      LLLL: 'EEEE d MMMM yyyy HH:mm'
    }
  }; /* loosely based on the CLX framework */

  const language = (0, _translate.getLanguage)();
  const locale = language === 'de-CH' ? _de.default : _fr.default;

  /**
   * format a date
   * @param {date|string|number|null} date the date to format
   * @param {string} formatString format or longDateFormat from moment.js
   */
  function formatDate(date, formatString = '') {
    if (date === null) return null;

    if (typeof date === 'string') date = (0, _parseISO.default)(date);

    formatString = formatString in formats[language] ? formats[language][formatString] : formatString;

    return (0, _format.default)(date, formatString, { locale });
  }

  /**
   * returns true when the current Date is between
   * SubscriptionDateFrom/SubscriptionTimeFrom and
   * SubscriptionDateTo/SubscriptionTimeTo
   * @param {object} event the event to check
   */
  function isInSubscriptionRange(event) {
    let now = new Date();

    if (event.SubscriptionFrom === null) return now.getTime() < event.SubscriptionTo.getTime();

    return event.SubscriptionFrom.getTime() < now.getTime() && now.getTime() < event.SubscriptionTo.getTime();
  }

  /**
   * return true if DateFrom or SubscriptionDateTo is greater than or equal
   * to the current date
   * @param {object} event event to check
   */
  function eventStarted(event) {
    let now = new Date();
    if (event.DateFrom === null) {
      return true;
    }
    let date = event.DateFrom <= event.SubscriptionDateTo ? event.SubscriptionDateTo : event.DateFrom;
    return (0, _parseISO.default)(date).getTime() >= now.getTime();
  }

  /**
   * return true if DateTo + TimeTo is smaller than or equal
   * to the current datetime
   * @param {object} event event to check
   */
  function eventEnded(event) {
    let now = new Date();
    let dateTo = event.DateTo;
    let repalcePattern = '00:00:00';
    if (dateTo === null) {
      return false;
    }

    dateTo = dateTo.search(repalcePattern) > 0 ? dateTo.replace(repalcePattern, event.TimeTo) : dateTo;
    return (0, _parseISO.default)(dateTo).getTime() <= now.getTime();
  }

  /**
   * combine a date and a time to a single date object
   * this returns null when it fails
   * @param {string} dateString a string containing the date
   * @param {string} timeString a string containing the time in the format hh:mm
   */
  function combineDate(dateString, timeString) {
    try {
      let [hours, minutes] = timeString.split(':').map(str => parseInt(str));
      let date = (0, _parseISO.default)(dateString);
      date.setHours(hours, minutes);
      return date;
    } catch (exception) {
      return null;
    }
  }

  /**
   * return timeString in format 00:00 if it has the format hh:mm:ss
   * @param {string} timeString the string to remove the time from
   */
  function removeMinutes(timeString) {
    return timeString.replace(/^(\d\d:\d\d):\d\d$/g, '$1');
  }

  /**
   * returns true if the format is DD.MM.YYYY
   * @param {string} dateString the date to check
   */
  function isDMY(dateString) {
    return (/^[0-9]{2}.[0-9]{2}.[0-9]{4}$/.test(dateString)
    );
  }

  /**
   * returns dateString in the format DD.MM.YYYY
   * @param {string} dateString the date to convert
   */
  function getDMY(dateString) {
    return isDMY(dateString) ? dateString : formatDate(dateString, 'L');
  }

  /**
   * returns dateString in the format YYYY-MM-DD
   * @param {string} dateString the date to convert
   */
  function getYMD(dateString) {
    return isDMY(dateString) ? dateString.split('.').reverse().join('-') : formatDate(dateString, 'yyyy-MM-dd');
  }

  /**
   * returns dateString in from format yyyy-mm-ddThh:mm:ss to yyyy\mm\dd hh:mm:ss
   * @param {string} dateString the date to convert
   */
  function getDateTimeForIcs(dateString) {
    return dateString.replace(new RegExp('-', 'g'), '/').replace(new RegExp('T', 'g'), ' ');
  }

  /**
   * returns true if date > now
   * @param {string} dateString YYYY-MM-DD
   */

  function dateGreaterNow(date) {
    return (0, _parseISO.default)(date) > Date.now() ? true : false;
  }
});