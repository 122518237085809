define('kursausschreibung/routes/list/category/event', ['exports', 'kursausschreibung/framework/store'], function (exports, _store) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model(params) {
      let event = _store.default.getEventById(params.event_id);

      // check if event exists in area and category
      let areaKey = Ember.String.underscore(this.paramsFor('list').area_of_education);
      let categoryKey = Ember.String.underscore(this.paramsFor('list.category').category);

      if (event === undefined || event.areaKey !== areaKey || event.categoryKey !== categoryKey) {
        this.replaceWith('list.category');
        return;
      }

      return event;
    }
  });
});