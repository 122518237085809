define('kursausschreibung/components/input/input-email', ['exports', 'kursausschreibung/framework/form-helpers'], function (exports, _formHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    change() {
      // show an error message for duplicate e-mails
      const emailFields = this.$().closest('form').find('input[type="email"]').toArray();
      const emailFieldValues = emailFields.map(field => field.value);

      emailFields.forEach((field, fieldIndex) => {
        const valueIndex = emailFieldValues.indexOf(field.value);

        if (valueIndex !== -1 && valueIndex < fieldIndex) {
          (0, _formHelpers.formFieldError)(field, true, 'duplicateEmailError');
        } else {
          (0, _formHelpers.formFieldError)(field, false);
        }
      });
    },
    keyUp() {
      this.change();
    }
  });
});