define('date-fns/locale/fr', ['exports'], function (exports) { 'use strict';

	function unwrapExports (x) {
		return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default') ? x['default'] : x;
	}

	function createCommonjsModule(fn, module) {
		return module = { exports: {} }, fn(module, module.exports), module.exports;
	}

	var formatDistance_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = formatDistance;
	var formatDistanceLocale = {
	  lessThanXSeconds: {
	    one: 'moins d’une seconde',
	    other: 'moins de {{count}} secondes'
	  },
	  xSeconds: {
	    one: '1 seconde',
	    other: '{{count}} secondes'
	  },
	  halfAMinute: '30 secondes',
	  lessThanXMinutes: {
	    one: 'moins d’une minute',
	    other: 'moins de {{count}} minutes'
	  },
	  xMinutes: {
	    one: '1 minute',
	    other: '{{count}} minutes'
	  },
	  aboutXHours: {
	    one: 'environ 1 heure',
	    other: 'environ {{count}} heures'
	  },
	  xHours: {
	    one: '1 heure',
	    other: '{{count}} heures'
	  },
	  xDays: {
	    one: '1 jour',
	    other: '{{count}} jours'
	  },
	  aboutXWeeks: {
	    one: 'environ 1 semaine',
	    other: 'environ {{count}} semaines'
	  },
	  xWeeks: {
	    one: '1 semaine',
	    other: '{{count}} semaines'
	  },
	  aboutXMonths: {
	    one: 'environ 1 mois',
	    other: 'environ {{count}} mois'
	  },
	  xMonths: {
	    one: '1 mois',
	    other: '{{count}} mois'
	  },
	  aboutXYears: {
	    one: 'environ 1 an',
	    other: 'environ {{count}} ans'
	  },
	  xYears: {
	    one: '1 an',
	    other: '{{count}} ans'
	  },
	  overXYears: {
	    one: 'plus d’un an',
	    other: 'plus de {{count}} ans'
	  },
	  almostXYears: {
	    one: 'presqu’un an',
	    other: 'presque {{count}} ans'
	  }
	};

	function formatDistance(token, count, options) {
	  options = options || {};
	  var result;

	  if (typeof formatDistanceLocale[token] === 'string') {
	    result = formatDistanceLocale[token];
	  } else if (count === 1) {
	    result = formatDistanceLocale[token].one;
	  } else {
	    result = formatDistanceLocale[token].other.replace('{{count}}', count);
	  }

	  if (options.addSuffix) {
	    if (options.comparison > 0) {
	      return 'dans ' + result;
	    } else {
	      return 'il y a ' + result;
	    }
	  }

	  return result;
	}

	module.exports = exports.default;
	});

	unwrapExports(formatDistance_1);

	var buildFormatLongFn_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = buildFormatLongFn;

	function buildFormatLongFn(args) {
	  return function (dirtyOptions) {
	    var options = dirtyOptions || {};
	    var width = options.width ? String(options.width) : args.defaultWidth;
	    var format = args.formats[width] || args.formats[args.defaultWidth];
	    return format;
	  };
	}

	module.exports = exports.default;
	});

	unwrapExports(buildFormatLongFn_1);

	var formatLong_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = void 0;

	var _index = _interopRequireDefault(buildFormatLongFn_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	var dateFormats = {
	  full: 'EEEE d MMMM y',
	  long: 'd MMMM y',
	  medium: 'd MMM y',
	  short: 'dd/MM/y'
	};
	var timeFormats = {
	  full: 'HH:mm:ss zzzz',
	  long: 'HH:mm:ss z',
	  medium: 'HH:mm:ss',
	  short: 'HH:mm'
	};
	var dateTimeFormats = {
	  full: "{{date}} 'à' {{time}}",
	  long: "{{date}} 'à' {{time}}",
	  medium: '{{date}}, {{time}}',
	  short: '{{date}}, {{time}}'
	};
	var formatLong = {
	  date: (0, _index.default)({
	    formats: dateFormats,
	    defaultWidth: 'full'
	  }),
	  time: (0, _index.default)({
	    formats: timeFormats,
	    defaultWidth: 'full'
	  }),
	  dateTime: (0, _index.default)({
	    formats: dateTimeFormats,
	    defaultWidth: 'full'
	  })
	};
	var _default = formatLong;
	exports.default = _default;
	module.exports = exports.default;
	});

	unwrapExports(formatLong_1);

	var formatRelative_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = formatRelative;
	var formatRelativeLocale = {
	  lastWeek: "eeee 'dernier à' p",
	  yesterday: "'hier à' p",
	  today: "'aujourd’hui à' p",
	  tomorrow: "'demain à' p'",
	  nextWeek: "eeee 'prochain à' p",
	  other: 'P'
	};

	function formatRelative(token, _date, _baseDate, _options) {
	  return formatRelativeLocale[token];
	}

	module.exports = exports.default;
	});

	unwrapExports(formatRelative_1);

	var buildLocalizeFn_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = buildLocalizeFn;

	function buildLocalizeFn(args) {
	  return function (dirtyIndex, dirtyOptions) {
	    var options = dirtyOptions || {};
	    var context = options.context ? String(options.context) : 'standalone';
	    var valuesArray;

	    if (context === 'formatting' && args.formattingValues) {
	      var defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
	      var width = options.width ? String(options.width) : defaultWidth;
	      valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
	    } else {
	      var _defaultWidth = args.defaultWidth;

	      var _width = options.width ? String(options.width) : args.defaultWidth;

	      valuesArray = args.values[_width] || args.values[_defaultWidth];
	    }

	    var index = args.argumentCallback ? args.argumentCallback(dirtyIndex) : dirtyIndex;
	    return valuesArray[index];
	  };
	}

	module.exports = exports.default;
	});

	unwrapExports(buildLocalizeFn_1);

	var localize_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = void 0;

	var _index = _interopRequireDefault(buildLocalizeFn_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	var eraValues = {
	  narrow: ['av. J.-C', 'ap. J.-C'],
	  abbreviated: ['av. J.-C', 'ap. J.-C'],
	  wide: ['avant Jésus-Christ', 'après Jésus-Christ']
	};
	var quarterValues = {
	  narrow: ['T1', 'T2', 'T3', 'T4'],
	  abbreviated: ['1er trim.', '2ème trim.', '3ème trim.', '4ème trim.'],
	  wide: ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre']
	};
	var monthValues = {
	  narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
	  abbreviated: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
	  wide: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
	};
	var dayValues = {
	  narrow: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
	  short: ['di', 'lu', 'ma', 'me', 'je', 've', 'sa'],
	  abbreviated: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
	  wide: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
	};
	var dayPeriodValues = {
	  narrow: {
	    am: 'AM',
	    pm: 'PM',
	    midnight: 'minuit',
	    noon: 'midi',
	    morning: 'mat.',
	    afternoon: 'ap.m.',
	    evening: 'soir',
	    night: 'mat.'
	  },
	  abbreviated: {
	    am: 'AM',
	    pm: 'PM',
	    midnight: 'minuit',
	    noon: 'midi',
	    morning: 'matin',
	    afternoon: 'après-midi',
	    evening: 'soir',
	    night: 'matin'
	  },
	  wide: {
	    am: 'AM',
	    pm: 'PM',
	    midnight: 'minuit',
	    noon: 'midi',
	    morning: 'du matin',
	    afternoon: 'de l’après-midi',
	    evening: 'du soir',
	    night: 'du matin'
	  }
	};

	function ordinalNumber(dirtyNumber, dirtyOptions) {
	  var number = Number(dirtyNumber);
	  var options = dirtyOptions || {};
	  var unit = String(options.unit);
	  var suffix;

	  if (number === 0) {
	    return number;
	  }

	  if (unit === 'year' || unit === 'hour' || unit === 'week') {
	    if (number === 1) {
	      suffix = 'ère';
	    } else {
	      suffix = 'ème';
	    }
	  } else {
	    if (number === 1) {
	      suffix = 'er';
	    } else {
	      suffix = 'ème';
	    }
	  }

	  return number + suffix;
	}

	var localize = {
	  ordinalNumber: ordinalNumber,
	  era: (0, _index.default)({
	    values: eraValues,
	    defaultWidth: 'wide'
	  }),
	  quarter: (0, _index.default)({
	    values: quarterValues,
	    defaultWidth: 'wide',
	    argumentCallback: function (quarter) {
	      return Number(quarter) - 1;
	    }
	  }),
	  month: (0, _index.default)({
	    values: monthValues,
	    defaultWidth: 'wide'
	  }),
	  day: (0, _index.default)({
	    values: dayValues,
	    defaultWidth: 'wide'
	  }),
	  dayPeriod: (0, _index.default)({
	    values: dayPeriodValues,
	    defaultWidth: 'wide'
	  })
	};
	var _default = localize;
	exports.default = _default;
	module.exports = exports.default;
	});

	unwrapExports(localize_1);

	var buildMatchPatternFn_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = buildMatchPatternFn;

	function buildMatchPatternFn(args) {
	  return function (dirtyString, dirtyOptions) {
	    var string = String(dirtyString);
	    var options = dirtyOptions || {};
	    var matchResult = string.match(args.matchPattern);

	    if (!matchResult) {
	      return null;
	    }

	    var matchedString = matchResult[0];
	    var parseResult = string.match(args.parsePattern);

	    if (!parseResult) {
	      return null;
	    }

	    var value = args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0];
	    value = options.valueCallback ? options.valueCallback(value) : value;
	    return {
	      value: value,
	      rest: string.slice(matchedString.length)
	    };
	  };
	}

	module.exports = exports.default;
	});

	unwrapExports(buildMatchPatternFn_1);

	var buildMatchFn_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = buildMatchFn;

	function buildMatchFn(args) {
	  return function (dirtyString, dirtyOptions) {
	    var string = String(dirtyString);
	    var options = dirtyOptions || {};
	    var width = options.width;
	    var matchPattern = width && args.matchPatterns[width] || args.matchPatterns[args.defaultMatchWidth];
	    var matchResult = string.match(matchPattern);

	    if (!matchResult) {
	      return null;
	    }

	    var matchedString = matchResult[0];
	    var parsePatterns = width && args.parsePatterns[width] || args.parsePatterns[args.defaultParseWidth];
	    var value;

	    if (Object.prototype.toString.call(parsePatterns) === '[object Array]') {
	      value = findIndex(parsePatterns, function (pattern) {
	        return pattern.test(matchedString);
	      });
	    } else {
	      value = findKey(parsePatterns, function (pattern) {
	        return pattern.test(matchedString);
	      });
	    }

	    value = args.valueCallback ? args.valueCallback(value) : value;
	    value = options.valueCallback ? options.valueCallback(value) : value;
	    return {
	      value: value,
	      rest: string.slice(matchedString.length)
	    };
	  };
	}

	function findKey(object, predicate) {
	  for (var key in object) {
	    if (object.hasOwnProperty(key) && predicate(object[key])) {
	      return key;
	    }
	  }
	}

	function findIndex(array, predicate) {
	  for (var key = 0; key < array.length; key++) {
	    if (predicate(array[key])) {
	      return key;
	    }
	  }
	}

	module.exports = exports.default;
	});

	unwrapExports(buildMatchFn_1);

	var match_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = void 0;

	var _index = _interopRequireDefault(buildMatchPatternFn_1);

	var _index2 = _interopRequireDefault(buildMatchFn_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	var matchOrdinalNumberPattern = /^(\d+)(ième|ère|ème|er|e)?/i;
	var parseOrdinalNumberPattern = /\d+/i;
	var matchEraPatterns = {
	  narrow: /^(av\.J\.C|ap\.J\.C|ap\.J\.-C)/i,
	  abbreviated: /^(av\.J\.-C|av\.J-C|apr\.J\.-C|apr\.J-C|ap\.J-C)/i,
	  wide: /^(avant Jésus-Christ|après Jésus-Christ)/i
	};
	var parseEraPatterns = {
	  any: [/^av/i, /^ap/i]
	};
	var matchQuarterPatterns = {
	  narrow: /^[1234]/i,
	  abbreviated: /^t[1234]/i,
	  wide: /^[1234](er|ème|e)? trimestre/i
	};
	var parseQuarterPatterns = {
	  any: [/1/i, /2/i, /3/i, /4/i]
	};
	var matchMonthPatterns = {
	  narrow: /^[jfmasond]/i,
	  abbreviated: /^(janv|févr|mars|avr|mai|juin|juill|juil|août|sept|oct|nov|déc)\.?/i,
	  wide: /^(janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)/i
	};
	var parseMonthPatterns = {
	  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
	  any: [/^ja/i, /^f/i, /^mar/i, /^av/i, /^ma/i, /^juin/i, /^juil/i, /^ao/i, /^s/i, /^o/i, /^n/i, /^d/i]
	};
	var matchDayPatterns = {
	  narrow: /^[lmjvsd]/i,
	  short: /^(di|lu|ma|me|je|ve|sa)/i,
	  abbreviated: /^(dim|lun|mar|mer|jeu|ven|sam)\.?/i,
	  wide: /^(dimanche|lundi|mardi|mercredi|jeudi|vendredi|samedi)/i
	};
	var parseDayPatterns = {
	  narrow: [/^d/i, /^l/i, /^m/i, /^m/i, /^j/i, /^v/i, /^s/i],
	  any: [/^di/i, /^lu/i, /^ma/i, /^me/i, /^je/i, /^ve/i, /^sa/i]
	};
	var matchDayPeriodPatterns = {
	  narrow: /^(a|p|minuit|midi|mat\.?|ap\.?m\.?|soir|nuit)/i,
	  any: /^([ap]\.?\s?m\.?|du matin|de l'après[-\s]midi|du soir|de la nuit)/i
	};
	var parseDayPeriodPatterns = {
	  any: {
	    am: /^a/i,
	    pm: /^p/i,
	    midnight: /^min/i,
	    noon: /^mid/i,
	    morning: /mat/i,
	    afternoon: /ap/i,
	    evening: /soir/i,
	    night: /nuit/i
	  }
	};
	var match = {
	  ordinalNumber: (0, _index.default)({
	    matchPattern: matchOrdinalNumberPattern,
	    parsePattern: parseOrdinalNumberPattern,
	    valueCallback: function (value) {
	      return parseInt(value, 10);
	    }
	  }),
	  era: (0, _index2.default)({
	    matchPatterns: matchEraPatterns,
	    defaultMatchWidth: 'wide',
	    parsePatterns: parseEraPatterns,
	    defaultParseWidth: 'any'
	  }),
	  quarter: (0, _index2.default)({
	    matchPatterns: matchQuarterPatterns,
	    defaultMatchWidth: 'wide',
	    parsePatterns: parseQuarterPatterns,
	    defaultParseWidth: 'any',
	    valueCallback: function (index) {
	      return index + 1;
	    }
	  }),
	  month: (0, _index2.default)({
	    matchPatterns: matchMonthPatterns,
	    defaultMatchWidth: 'wide',
	    parsePatterns: parseMonthPatterns,
	    defaultParseWidth: 'any'
	  }),
	  day: (0, _index2.default)({
	    matchPatterns: matchDayPatterns,
	    defaultMatchWidth: 'wide',
	    parsePatterns: parseDayPatterns,
	    defaultParseWidth: 'any'
	  }),
	  dayPeriod: (0, _index2.default)({
	    matchPatterns: matchDayPeriodPatterns,
	    defaultMatchWidth: 'any',
	    parsePatterns: parseDayPeriodPatterns,
	    defaultParseWidth: 'any'
	  })
	};
	var _default = match;
	exports.default = _default;
	module.exports = exports.default;
	});

	unwrapExports(match_1);

	var fr = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = void 0;

	var _index = _interopRequireDefault(formatDistance_1);

	var _index2 = _interopRequireDefault(formatLong_1);

	var _index3 = _interopRequireDefault(formatRelative_1);

	var _index4 = _interopRequireDefault(localize_1);

	var _index5 = _interopRequireDefault(match_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * @type {Locale}
	 * @category Locales
	 * @summary French locale.
	 * @language French
	 * @iso-639-2 fra
	 * @author Jean Dupouy [@izeau]{@link https://github.com/izeau}
	 * @author François B [@fbonzon]{@link https://github.com/fbonzon}
	 */
	var locale = {
	  code: 'fr',
	  formatDistance: _index.default,
	  formatLong: _index2.default,
	  formatRelative: _index3.default,
	  localize: _index4.default,
	  match: _index5.default,
	  options: {
	    weekStartsOn: 1
	    /* Monday */
	    ,
	    firstWeekContainsDate: 1
	  }
	};
	var _default = locale;
	exports.default = _default;
	module.exports = exports.default;
	});

	var index$9 = unwrapExports(fr);

	exports.default = index$9;

	Object.defineProperty(exports, '__esModule', { value: true });

});
