define('kursausschreibung/controllers/list/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    page: 1,
    queryParams: ['page'],

    actions: {
      queryChanged() {
        // reset page
        this.set('page', 1);
      }
    }
  });
});