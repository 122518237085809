define('kursausschreibung/components/twitter-feed', ['exports', 'kursausschreibung/framework/translate'], function (exports, _translate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    language: (0, _translate.getLanguage)().split('-')[0]
  }).reopenClass({
    positionalParams: ['username']
  });
});