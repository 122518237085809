define('kursausschreibung/controllers/application', ['exports', 'kursausschreibung/framework/translate', 'kursausschreibung/framework/settings'], function (exports, _translate, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let rightWidth = _settings.default.displayRightSide ? 'uk-width-1-4@l' : 'uk-width-1-1';

  exports.default = Ember.Controller.extend({
    showLanguageButton: _settings.default.showLanguageButton,
    logoImage: _settings.default.logoImage,
    logoLink: _settings.default.logoLink,
    showContact: _settings.default.showContact,
    twitterHandle: _settings.default.twitterHandle,
    eventCategoryDropdown: _settings.default.eventCategoryDropdown,
    rightWidth,

    actions: {
      setLanguage(language) {
        (0, _translate.setLanguage)(language);
      }
    }
  });
});