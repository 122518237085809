define('kursausschreibung/framework/store', ['exports', 'kursausschreibung/framework/api', 'kursausschreibung/framework/status', 'kursausschreibung/framework/date-helpers', 'kursausschreibung/framework/settings', 'kursausschreibung/framework/translate', 'date-fns/format'], function (exports, _api, _status, _dateHelpers, _settings, _translate, _format) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isInitialized = isInitialized;
  exports.getAllEvents = getAllEvents;
  exports.getEventById = getEventById;
  exports.init = init;


  let initialized = false;

  /**
   * this returns true if init completed successfully
   */
  function isInitialized() {
    return initialized;
  }

  // group events by areaOfEducation, EventCategory and Id
  let eventsByArea = { areas: {}, areaKeys: [] };
  let eventsById = [];

  /**
   * get all events grouped by areaOfEducation
   */
  function getAllEvents() {
    return eventsByArea;
  }

  /**
   * get an event by id
   * @param {number} id the id of the event
   */
  function getEventById(id) {
    if (eventsById.hasOwnProperty(id)) {
      return eventsById[id];
    }

    return undefined;
  }

  /**
   * this function initializes the store by:
   *  + fetching and storing events, lessons locations and texts
   *  + filtering and sorting events
   *  + adding properties to events
   */
  function init() {
    let language = (0, _translate.getLanguage)() === 'fr-CH' ? 'en-US' : 'de-CH';

    // fetch all events
    return Ember.RSVP.all([(0, _api.getEvents)(), (0, _api.getLessons)(), (0, _api.getEventLocations)(), (0, _api.getEventTexts)(language), (0, _api.getEventCodes)()]).then(function ([events, lessons, eventLocations, eventTexts, eventCodes]) {

      // filter events
      events = filterEvents(events, language, eventCodes);

      // sort events
      if (_settings.default.sortEventList !== null) {
        events = Ember.A(events).sortBy(_settings.default.sortEventList);
      }

      // prepare events
      events.forEach(prepareEvent);

      // add lessons to events
      addLessonsToEvents(lessons);

      // add eventLocations to events
      addLocationsToEvents(eventLocations);

      // add texts to events
      addTextsToEvents(eventTexts, language);

      // add codes to events (it's important to filter)
      addCodesToEvents(eventCodes);

      // sort areaKeys
      eventsByArea.areaKeys = Object.keys(eventsByArea.areas).sort();

      // sort categoryKeys
      eventsByArea.areaKeys.forEach(area => eventsByArea.areas[area].categoryKeys = Object.keys(eventsByArea.areas[area].categories).sort());

      initialized = true;
    });
  }

  /**
   * add texts to events
   * @param {object[]} eventTexts eventTexts returned by the API
   * @param {string} language the active language
   */
  function addTextsToEvents(eventTexts, language) {
    eventTexts.forEach(function (textItem) {
      if (!eventsById.hasOwnProperty(textItem.EventId)) {
        return;
      }

      // only show texts with the correct cultureInfo
      if (textItem.CultureInfo !== language) {
        return;
      }

      let text = eventsById[textItem.EventId].texts[textItem.Number];

      if (text === undefined) {
        text = eventsById[textItem.EventId].texts[textItem.Number] = {
          label: null,
          memo: null,
          id: textItem.Number
        };
      }

      text[textItem.Type.toLowerCase()] = textItem.Value;
    });

    // if the 13th event text is an url it is used to subscribe to the event
    // see: https://github.com/bkd-mba-fbi/kursausschreibung/issues/67
    eventsById.forEach(event => {
      if (event.texts.length >= 14 && /^https?:\/\/[^ ]+$/.test(event.texts[13].memo)) {
        event.externalSubscriptionURL = event.texts[13].memo;
        event.texts[13].memo = null;
      } else {
        event.externalSubscriptionURL = null;
      }
    });

    // remove texts with empty label or memo
    eventsById.forEach(event => event.texts = event.texts.filter(text => text.label !== null && text.memo !== null));
  }

  /**
   * add locations to events
   * @param {object[]} eventLocations eventLocations returned by the API
   */
  function addLocationsToEvents(eventLocations) {
    eventLocations.forEach(function (location) {
      let eventId = location.EventId;

      if (!eventsById.hasOwnProperty(eventId)) {
        return;
      }

      // don't overwrite the event-Id
      delete location.Id;
      eventsById[eventId] = Ember.$.extend(eventsById[eventId], location);
    });
  }

  /**
   * add lessons to events
   * @param {object[]} lessons lessons returned by the API
   */
  function addLessonsToEvents(lessons) {
    lessons.forEach(function (lesson) {
      if (!eventsById.hasOwnProperty(lesson.EventId)) {
        return;
      }

      // make DateFrom and DateTo human-readable
      lesson.DateFrom = (0, _dateHelpers.formatDate)(lesson.DateTimeFrom, 'LLL');
      lesson.TimeTo = (0, _dateHelpers.formatDate)(lesson.DateTimeTo, 'LT');

      eventsById[lesson.EventId].lessons.push(lesson);
    });
  }

  /**
   * add Codes to events
   * @param {object[]} eventCodes eventCodes returned by the API
   */
  function addCodesToEvents(eventCodes) {

    // add all codes to event
    let prefix = 'FilterTag';
    let filterCodes = [];
    let codeIds = [];
    eventCodes.forEach(function (code) {

      if (codeIds.find(ids => ids === code.CodeId) === undefined) {
        codeIds.push(code.CodeId);
        let codeName = (0, _translate.getString)(prefix + code.CodeId).indexOf('<span style="color:red;">Key not found:') >= 0 ? code.Code : (0, _translate.getString)(prefix + code.CodeId);
        filterCodes.push({ id: code.CodeId, Code: codeName });
      }
    });

    eventCodes.forEach(function (code) {

      if (!eventsById.hasOwnProperty(code.EventId)) {
        return;
      }
      // add codes-array
      if (eventsById[code.EventId].codes === undefined) {
        eventsById[code.EventId].codes = [];
      }

      eventsById[code.EventId].codes.push(code);

      // adds filter tag
      let filter = eventsById[code.EventId].filter;
      eventsById[code.EventId].filter = filter === undefined ? 'tag' + code.CodeId : filter + ' tag' + code.CodeId;
      eventsById[code.EventId].allfilterCodes = filterCodes;
    });
  }

  /**
   * filter out events based on settings
   * @param {object[]} events events returned by the API
   * @param {string} language the active language
   */
  function filterEvents(events, language, eventCodes) {
    // filter out events with undesired parameters

    // backwards compatibility fallback for single hostId filter
    if (_settings.default.hostIds instanceof Array) {
      events = events.filter(event => _settings.default.hostIds.indexOf(event.HostId) !== -1);
    }
    // or use initialListFilters array
    else if (_settings.default.initialListFilters instanceof Object) {
        if (_settings.default.initialListFilters.hostIds instanceof Array) {
          events = events.filter(event => _settings.default.initialListFilters.hostIds.indexOf(event.HostId) !== -1);
        }

        if (_settings.default.initialListFilters.eventCategoryIds instanceof Array) {
          events = events.filter(event => _settings.default.initialListFilters.eventCategoryIds.indexOf(event.EventCategoryId) !== -1);
        }

        if (_settings.default.initialListFilters.eventLevelIds instanceof Array) {
          events = events.filter(event => _settings.default.initialListFilters.eventLevelIds.indexOf(event.EventLevelId) !== -1);
        }

        if (_settings.default.initialListFilters.eventTypeIds instanceof Array) {
          events = events.filter(event => _settings.default.initialListFilters.eventTypeIds.indexOf(event.EventTypeId) !== -1);
        }

        if (_settings.default.initialListFilters.statusIds instanceof Array) {
          events = events.filter(event => _settings.default.initialListFilters.statusIds.indexOf(event.StatusId) !== -1);
        }

        if (_settings.default.initialListFilters.codeIds instanceof Array) {
          eventCodes = eventCodes.filter(code => _settings.default.initialListFilters.codeIds.indexOf(code.CodeId) !== -1);
          let codes = [];
          eventCodes.forEach(eventcode => {
            codes.push(eventcode.EventId);
          });
          events = events.filter(event => codes.indexOf(event.Id) !== -1);
        }
      }

    // filter out events with non-matching LanguageOfInstruction
    if (_settings.default.languageOfInstructionFilter) {
      events = events.filter(event => event.LanguageOfInstruction === 'Bilingue' || event.LanguageOfInstruction === "1" && language === 'de-CH' || event.LanguageOfInstruction === 'Deutsch' && language === 'de-CH' || event.LanguageOfInstruction === "2" && language === 'en-US' || event.LanguageOfInstruction === 'Französisch' && language === 'en-US');
    }

    if (_settings.default.showStartedEvents) {
      // Filter out events which have not ended yet
      events = events.filter(event => !(0, _dateHelpers.eventEnded)(event));
    } else {
      // Default behaviour, filter out events which have started
      events = events.filter(event => (0, _dateHelpers.eventStarted)(event));
    }

    return events;
  }

  /**
   * this function adds properties and the displayObject to every event
   * transforms every event into an ember-object
   * and sorts events by id, area and category
   * @param {object} event event returned by the API
   */
  function prepareEvent(event) {

    // add properties to the events
    addPropertiesToEvent(event);

    // set LanguageOfInstruction, if int to string translate value
    setLanguageEventFromIntToString(event);

    // create proxy for human-readable values
    addDisplayData(event);

    //settings subscriptionWithLoginURL
    event.subscriptionWithLoginURL = _settings.default.subscriptionWithLoginURL;

    //event subtitle when > inside string
    let eventSubtitle = event.Designation.split(_settings.default.eventSubtitle);
    event.Designation = eventSubtitle.length > 1 ? eventSubtitle[0] : event.Designation;
    event.subtitle = eventSubtitle.length > 1 ? eventSubtitle[1] : null;

    // create an ember-object of the event
    event = createEmberObject(event);

    // put event into associative arrays
    putIntoAssocArrays(event);
  }

  /**
   * put an event into associative arrays for the getEventById
   * and getAllEvents functions
   * @param {object} event event returned by the API
   */
  function putIntoAssocArrays(event) {
    // id
    eventsById[event.Id] = event;

    // area
    let areaName = event.AreaOfEducation;
    let areaKey = event.areaKey = Ember.String.underscore(areaName);

    if (!eventsByArea.areas.hasOwnProperty(areaKey)) {
      eventsByArea.areas[areaKey] = {
        name: areaName,
        key: areaKey,
        events: [],
        categories: {},
        categoryKeys: []
      };
    }
    eventsByArea.areas[areaKey].events.push(event);

    // category (in area)
    let categoryName = event.EventCategory;
    let categoryKey = event.categoryKey = Ember.String.underscore(categoryName);

    if (!eventsByArea.areas[areaKey].categories.hasOwnProperty(categoryKey)) {
      eventsByArea.areas[areaKey].categories[categoryKey] = {
        name: categoryName,
        key: categoryKey,
        events: []
      };
    }
    eventsByArea.areas[areaKey].categories[categoryKey].events.push(event);
  }

  /**
   * transforms an event into an ember-object with the computed
   * properties status and and canDoSubscription and an update method
   * @param {object} event event returned by the API
   */
  function createEmberObject(event) {
    return Ember.Object.extend({

      status: Ember.computed('FreeSeats', function () {
        if ((0, _status.isGreen)(this, _dateHelpers.isInSubscriptionRange)) {
          return 'green';
        }

        if ((0, _status.isChartreuse)(this, _dateHelpers.isInSubscriptionRange)) {
          return 'chartreuse';
        }

        if ((0, _status.isYellow)(this, _dateHelpers.isInSubscriptionRange)) {
          return 'yellow';
        }
        if ((0, _status.isRed)(this, _dateHelpers.isInSubscriptionRange)) {
          return 'red';
        }

        return 'orange';
      }),

      canDoSubscription: Ember.computed('status', function () {
        let status = this.get('status');
        return typeof _settings.default.canDoSubscription === 'object' && _settings.default.canDoSubscription[status] === true;
      }),

      update() {
        // only update FreeSeats for now
        let that = this;
        return (0, _api.getEvent)(this.get('Id')).then(function (updatedEvent) {
          that.set('FreeSeats', updatedEvent.FreeSeats);
        });
      }
    }).create(event);
  }

  /**
   * create proxy for human-readable values
   * @param {object} event event returned by the API
   */
  function addDisplayData(event) {
    event.displayData = Ember.ObjectProxy.create({
      content: event,

      // formatted overwritten properties
      DateFrom: (0, _dateHelpers.formatDate)(event.DateFrom, 'LL'),
      DateTo: (0, _dateHelpers.formatDate)(event.DateTo, 'LL'),

      SubscriptionDateFrom: (0, _dateHelpers.formatDate)(event.SubscriptionDateFrom, 'LL'),
      SubscriptionDateTo: (0, _dateHelpers.formatDate)(event.SubscriptionDateTo, 'LL'),

      From: (0, _dateHelpers.formatDate)(event.From, 'LLL'),
      To: (0, _dateHelpers.formatDate)(event.To, 'LLL'),

      SubscriptionFrom: (0, _dateHelpers.formatDate)(event.SubscriptionFrom, 'LLL'),
      SubscriptionTo: (0, _dateHelpers.formatDate)(event.SubscriptionTo, 'LLL'),

      Price: 'CHF ' + event.Price
    });
  }

  /**
   * adds empty arrays for lessons, texts and codes and adds properties SubscriptionFrom,
   * SubscriptionTo, From, To, Time
   * @param {object} event event returned by the API
   */
  function addPropertiesToEvent(event) {
    // add lessons-array
    event.lessons = [];

    // add texts-array
    event.texts = [];

    // fill empty Date properties in event object
    fillEmptyDates(event);

    // combine date and time
    event.SubscriptionFrom = (0, _dateHelpers.combineDate)(event.SubscriptionDateFrom, event.SubscriptionTimeFrom);
    event.SubscriptionTo = (0, _dateHelpers.combineDate)(event.SubscriptionDateTo, event.SubscriptionTimeTo);
    event.From = (0, _dateHelpers.combineDate)(event.DateFrom, event.TimeFrom);
    event.To = (0, _dateHelpers.combineDate)(event.DateTo, event.TimeTo);

    event.SubscriptionDateFrom = event.SubscriptionDateFromIsNull ? null : event.SubscriptionDateFrom;
    event.SubscriptionDateTo = event.SubscriptionDateToIsNull ? null : event.SubscriptionDateTo;

    // add event.Time
    if (typeof event.TimeFrom === 'string' && typeof event.TimeTo === 'string') {
      event.Time = `${(0, _dateHelpers.removeMinutes)(event.TimeFrom)} - ${(0, _dateHelpers.removeMinutes)(event.TimeTo)}`;
    }
  }
  /**
   * if one of the Date or Time property is null get default value
   *
   * SubscriptionDateFrom is null => now - 1 day
   * SubscriptionDateTo is null => now + 7 day
   * DateFrom is null => now + 7 day
   * DateTo is null => now + 7 day
   * SubscriptionTimeFrom is null => '00:00:01'
   * SubscriptionTimeTo is null => '23:59:59'
   * @param {object} event event returned by the API
   */
  function fillEmptyDates(event) {

    let now = new Date();
    let yesterday = new Date().setDate(now.getDate() - 1);
    let datePast = (0, _format.default)(yesterday, 'yyyy-MM-dd');
    now.setDate(now.getDate() + 7);
    let dateNow = (0, _format.default)(now, 'yyyy-MM-dd');

    event.SubscriptionDateFromIsNull = event.SubscriptionDateFrom === null ? true : false;
    event.SubscriptionDateFrom = event.SubscriptionDateFrom || datePast;
    event.SubscriptionDateToIsNull = event.SubscriptionDateTo === null ? true : false;
    event.SubscriptionDateTo = event.SubscriptionDateTo || dateNow;
    event.DateFrom = event.DateFrom || event.DateTo || dateNow;
    event.DateTo = event.DateTo || event.DateFrom || dateNow;
    event.SubscriptionTimeFrom = event.SubscriptionTimeFrom || '00:00:01';
    event.SubscriptionTimeTo = event.SubscriptionTimeTo || '23:59:59';
  }

  /**
   * if LanguageOfInstruction is a number translate it
   * @param {object} event event returned by the API
   */
  function setLanguageEventFromIntToString(event) {

    if (event.LanguageOfInstruction === '2') {
      event.LanguageOfInstruction = (0, _translate.getString)('french');
    } else if (event.LanguageOfInstruction === '1') {
      event.LanguageOfInstruction = (0, _translate.getString)('german');
    } else if (event.LanguageOfInstruction === '133') {
      event.LanguageOfInstruction = (0, _translate.getString)('english');
    } else if (event.LanguageOfInstruction === '284') {
      event.LanguageOfInstruction = (0, _translate.getString)('italian');
    } else if (event.LanguageOfInstruction === '285') {
      event.LanguageOfInstruction = (0, _translate.getString)('spain');
    }
  }
});