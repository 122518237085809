define('kursausschreibung/routes/application', ['exports', 'uikit', 'kursausschreibung/framework/store', 'kursausschreibung/framework/storage', 'kursausschreibung/framework/login-helpers'], function (exports, _uikit, _store, _storage, _loginHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel() {
      // set uikit scope
      _uikit.default.container = '.uk-scope';

      // initialization
      return (0, _loginHelpers.autoCheckForLogin)() // get a valid access_token if we don't have one
      .then(_store.init).then(() => {
        // reroute to the confirmation page if there is data that has to be submitted
        let dataToSubmit = (0, _storage.getDataToSubmit)();

        if (dataToSubmit !== null) {
          let event = (0, _store.getEventById)(dataToSubmit.eventId);
          this.replaceWith('list.category.event.confirmation', event.areaKey, event.categoryKey, event.Id);
        }
      }).catch(function (error) {
        // only log exceptions thrown here so the route still loads
        // uninitialized modules will throw an error later
        console.error('FATAL error while initializing the module: ', error); // eslint-disable-line no-console
      });
    },

    model() {
      // remove loader
      Ember.$('#kursausschreibung-loading').remove();

      return (0, _store.getAllEvents)();
    }
  });
});