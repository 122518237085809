define('kursausschreibung/components/input/input-textarea', ['exports', 'kursausschreibung/framework/form-helpers'], function (exports, _formHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        focusOut() {
            let field = this.get('field');
            let currentValue = document.getElementById('vss' + field.id).value;
            (0, _formHelpers.vssDependency)(currentValue, field);
        }
    });
});