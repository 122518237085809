define('kursausschreibung/controllers/list/category/event/subscribe', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      subscribe() {
        this.transitionToRoute('list.category.event.confirmation');
      }
    }
  });
});