define('kursausschreibung/routes/index', ['exports', 'kursausschreibung/framework/store'], function (exports, _store) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel() {
      let applicationModel = this.modelFor('application');

      if (applicationModel.areaKeys === undefined || applicationModel.areaKeys.length === 0) {
        if ((0, _store.isInitialized)()) {
          // proceed to the index route
          return;
        }

        throw new Error('failed to load.');
      }

      this.replaceWith('list', applicationModel.areaKeys[0]);
    }
  });
});