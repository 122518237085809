define('kursausschreibung/components/event-list', ['exports', 'kursausschreibung/framework/url-helpers'], function (exports, _urlHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function filterParam(getParam) {
    let filters = document.getElementsByClassName('filter-tag');
    let activeClass = 'uk-active';

    if (getParam) {
      let filterValue = (0, _urlHelpers.getParameterByName)('filter');

      for (let item of filters) {
        document.getElementById(item.id).classList.remove(activeClass);
        if (item.id.indexOf('tag' + filterValue) >= 0) {
          document.getElementById(item.id).classList.add(activeClass);
        }
      }
    } else {
      for (let item of filters) {
        if (item.className.indexOf(activeClass) >= 0) {
          (0, _urlHelpers.setParameterByName)('filter', item.id.substring(3, item.id.length));
        }
      }
    }
  }

  exports.default = Ember.Component.extend({
    actions: {
      queryChanged(query) {
        this.get('queryChanged')(query);
      }
    },
    didRender() {
      filterParam(true);
    },
    click() {
      filterParam(false);
    }
  });
});