define('kursausschreibung/components/event-details-table', ['exports', 'kursausschreibung/framework/settings', 'kursausschreibung/framework/translate', 'kursausschreibung/framework/ics-file'], function (exports, _settings, _translate, _icsFile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    title: _settings.default.eventDetailsTitle,
    showEventText: _settings.default.showEventText,

    fields: _settings.default.eventDetailsFields.map(key => ({
      name: (0, _translate.getString)(Ember.String.camelize(key)), key
    })),

    actions: {
      getIcsFileFromEvent() {
        (0, _icsFile.getIcsFileFromEvent)(this.event);
      }
    }

  });
});