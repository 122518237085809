define('kursausschreibung/controllers/list', ['exports', 'kursausschreibung/framework/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    eventCategoryDropdown: _settings.default.eventCategoryDropdown,

    centerWidth: (() => {
      let displayLeft = _settings.default.eventCategoryDropdown !== true;
      let displayRight = _settings.default.displayRightSide;

      // handle each combination of eventCategoryDropdown and displayRightSide
      // for every viewport-size

      if (!displayLeft && !displayRight) return 'uk-width-1-1';

      if (displayRight && displayLeft) return 'uk-width-3-4@m uk-width-1-2@l';

      if (displayLeft) return 'uk-width-3-4@m';

      return 'uk-width-3-4@l';
    })()
  });
});