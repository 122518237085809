define('kursausschreibung/components/input/input-freeform-dropdown', ['exports', 'kursausschreibung/framework/form-helpers'], function (exports, _formHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      let options = this.get('field.options').options.map(option => option.Value);

      this.$('.typeahead').typeahead({
        hint: true,
        highlight: true,
        minLength: 0
      }, {
        limit: 10,
        source: (query, callback) => {
          query = query.trim().toLowerCase();

          callback(options.filter(option => option.toLowerCase().indexOf(query) !== -1));
        }
      });
    },

    willDestroyElement() {
      this.$('.typeahead').typeahead('destroy');
      this._super(...arguments);
    },

    focusOut() {
      let field = this.get('field');
      let currentValue = document.getElementById('vss' + field.id).value;
      (0, _formHelpers.vssDependency)(currentValue, field);
    }
  });
});