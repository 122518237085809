define('kursausschreibung/framework/scroll-helpers', ['exports', 'kursausschreibung/framework/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.scrollToTimeout = scrollToTimeout;
  exports.setOffsetStickyHeader = setOffsetStickyHeader;


  /**
   * scroll to target after timeout
   * @param {string} elementId id of html element
   */
  function scrollToTimeout(elementId) {
    setTimeout(function () {
      scrollToTargetAdjusted(elementId);
    }, 500);
  }

  /**
   * set offset from settings.headerOffset to uk-sticky attribut
   * @param {string} elementId id of html element
   */
  function setOffsetStickyHeader(elementId) {
    document.getElementById(elementId).setAttribute('uk-sticky', 'offset: ' + _settings.default.headerOffset + '; bottom: #top');
  }

  /**
   * scroll to position of a element consider settings.headerOffset
   * @param {string} elementId id of html element
   */
  function scrollToTargetAdjusted(elementId) {
    var element = document.getElementById(elementId);
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = window.scrollY + elementPosition - _settings.default.headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
});