define('kursausschreibung/components/remaining-seats-badge', ['exports', 'kursausschreibung/framework/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      let event = this.get('event');

      event.update();

      let interval = typeof _settings.default.badgeFreeSeats === 'object' ? _settings.default.badgeFreeSeats.intervalSec : null;

      if (typeof interval !== 'number') {
        console.warn('settings.badgeFreeSeats.intervalSec not found. falling back to 30 seconds'); // eslint-disable-line no-console
        interval = 30;
      }

      // update freeSeats every <interval> seconds
      this.set('interval', setInterval(() => event.update(), interval * 1000));
    },

    willDestroyElement() {
      let interval = this.get('interval');

      if (interval !== undefined) clearInterval(interval);
    },

    hidden: Ember.computed('event.{FreeSeats,status}', function () {
      let freeSeats = this.get('event.FreeSeats');
      let status = this.get('event.status');
      let subscriptionYellowDisable = typeof _settings.default.badgeFreeSeats === 'object' ? _settings.default.badgeFreeSeats.subscriptionYellowDisable : false;

      return freeSeats === null || subscriptionYellowDisable && status === 'yellow';
    }),

    labelType: Ember.computed('event.FreeSeats', function () {
      return this.get('event.FreeSeats') > 5 ? 'warning' : 'danger';
    }),

    plural: Ember.computed('event.FreeSeats', function () {
      return this.get('event.FreeSeats') !== 1;
    })
  });
});