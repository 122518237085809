define('kursausschreibung/controllers/list/category/event/index', ['exports', 'kursausschreibung/framework/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let badgeFreeSeatsEnabled = typeof _settings.default.badgeFreeSeats === 'object' && _settings.default.badgeFreeSeats.enabled === true;

  exports.default = Ember.Controller.extend({
    showBreadcrumbs: _settings.default.showBreadcrumbs,
    badgeFreeSeatsEnabled
  });


  // bindings for tooltip and disabled attributes
  Ember.LinkComponent.reopen({
    attributeBindings: ['data-uk-tooltip', 'disabled']
  });
});