define('kursausschreibung/components/input/input-date', ['exports', 'kursausschreibung/framework/date-helpers', 'kursausschreibung/framework/form-helpers'], function (exports, _dateHelpers, _formHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        change() {
            if (this.field.id === 'Birthdate') {
                (0, _formHelpers.formFieldError)(this.element.children[0], (0, _dateHelpers.dateGreaterNow)(this.element.children[0].value));
            }
        },
        focusOut() {
            let field = this.get('field');
            let currentValue = document.getElementById('vss' + field.id).value;
            (0, _formHelpers.vssDependency)(currentValue, field);
        }
    });
});