define('kursausschreibung/helpers/translate', ['exports', 'kursausschreibung/framework/translate'], function (exports, _translate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.translate = translate;
  function translate([key, ...placeholderValues]) {
    return Ember.String.htmlSafe((0, _translate.getString)(key, placeholderValues));
  }

  exports.default = Ember.Helper.helper(translate);
});