define('kursausschreibung/router', ['exports', 'kursausschreibung/config/environment', 'kursausschreibung/framework/scroll-helpers'], function (exports, _environment, _scrollHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let rootElement = Ember.$(_environment.default.APP.rootElement).get(0);

  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    didTransition() {
      this._super(...arguments);

      var subscriptionProcessId = 'subscriptionProcess';

      setInterval(function () {
        if (document.getElementById(subscriptionProcessId) !== null) {
          (0, _scrollHelpers.setOffsetStickyHeader)(subscriptionProcessId);
        }
      }, 1000);

      if (this.currentPath === 'list.category.event.subscribe') {
        (0, _scrollHelpers.scrollToTimeout)(subscriptionProcessId);
      } else if (this.currentPath !== 'list.index') {
        (0, _scrollHelpers.scrollToTimeout)(rootElement.id);
      }
    }
  });

  Router.map(function () {
    this.route('permalink', { path: '/uid/:event_id' });
    this.route('list', { path: '/:area_of_education' }, function () {
      this.route('category', { path: '/:category' }, function () {
        this.route('event', { path: '/:event_id' }, function () {
          this.route('subscribe');
          this.route('confirmation-error');
          this.route('confirmation');
        });
      });
    });
  });

  exports.default = Router;
});