define('kursausschreibung/framework/status', ['exports', 'kursausschreibung/framework/date-helpers', 'kursausschreibung/framework/settings'], function (exports, _dateHelpers, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isRed = exports.isYellow = exports.isChartreuse = exports.isGreen = undefined;


  /**
   * return the callback specified in the settings or the
   * default implementation
   * @param {function?} settingsValue a custom implementation
   * @param {function} defaultImplementation the default implementation
   */
  function createStatusCallback(settingsValue, defaultImplementation) {
    if (typeof settingsValue === 'function') return settingsValue;

    return defaultImplementation;
  }

  // see "Event Status Definition" in documentation
  let isGreen = createStatusCallback(_settings.default.lampIsGreen, function (event) {
    return event.AllowSubscriptionInternetByStatus && event.TypeOfSubscription === 4 && (0, _dateHelpers.isInSubscriptionRange)(event) && (event.FreeSeats > 0 && event.MaxParticipants - event.FreeSeats < event.MinParticipants || event.EventTypeId === 1);
  });

  let isChartreuse = createStatusCallback(_settings.default.lampIsChartreuse, function (event) {
    return event.AllowSubscriptionInternetByStatus && event.TypeOfSubscription === 4 && (0, _dateHelpers.isInSubscriptionRange)(event) && event.FreeSeats > 0 && event.MaxParticipants - event.FreeSeats >= event.MinParticipants;
  });

  let isYellow = createStatusCallback(_settings.default.lampIsYellow, function (event) {
    return event.AllowSubscriptionInternetByStatus && event.TypeOfSubscription === 4 && !(0, _dateHelpers.isInSubscriptionRange)(event);
  });

  let isRed = createStatusCallback(_settings.default.lampIsRed, function (event) {
    return event.AllowSubscriptionInternetByStatus && event.TypeOfSubscription === 4 && event.FreeSeats === 0;
  });

  exports.isGreen = isGreen;
  exports.isChartreuse = isChartreuse;
  exports.isYellow = isYellow;
  exports.isRed = isRed;
});