define('kursausschreibung/framework/login-helpers', ['exports', 'kursausschreibung/framework/storage', 'kursausschreibung/framework/app-config', 'kursausschreibung/framework/url-helpers', 'kursausschreibung/framework/translate'], function (exports, _storage, _appConfig, _urlHelpers, _translate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.checkToken = checkToken;
  exports.autoCheckForLogin = autoCheckForLogin;


  /**
   * return true if there is a valid token in the localStorage
   */
  function isLoggedIn() {
    let accessToken = (0, _storage.getAccessToken)();
    let tokenExpire = (0, _storage.getTokenExpire)();

    if (accessToken === null || tokenExpire !== null && Date.now() >= tokenExpire) {
      return false;
    }

    if (_appConfig.default.useAutoLogin !== true) {
      // we can assume that instance and culture are correct
      return true;
    }

    let payload = parseJWT(accessToken);

    // only return true if instanceId and culture are correct
    return _appConfig.default.instanceId === payload.instance_id && payload.culture_info === (0, _translate.getLanguage)();
  }

  /**
   * parse accessToken and return the JWT payload
   * @param {string} accessToken the accessToken
   */
  function parseJWT(accessToken) {
    return JSON.parse(atob(accessToken.split('.')[1]));
  }

  // save the OAuth token if there is one in the URL
  function checkToken() {
    let accessToken = (0, _urlHelpers.getParameterByName)('access_token');

    if (accessToken !== null) {
      // store token, refresh token and expiration
      let refreshToken = (0, _urlHelpers.getParameterByName)('refresh_token');
      let tokenExpire = parseJWT(accessToken).exp * 1000;

      (0, _storage.setAccessToken)(accessToken);
      (0, _storage.setRefreshToken)(refreshToken);
      (0, _storage.setTokenExpire)(tokenExpire);

      // navigate back to initial url
      history.replaceState(null, null, (0, _urlHelpers.getParameterByName)('moduleRedirectUrl'));
    }
  }

  /**
   * return resolved promise if there is a valid token
   * get a new accesToken otherwise
   */
  function autoCheckForLogin() {
    if (isLoggedIn()) {
      return Ember.RSVP.Promise.resolve();
    }

    if (_appConfig.default.useAutoLogin === true) {
      // get a new token from the OAuth server
      let params = Ember.$.param({
        clientId: _appConfig.default.clientId,
        redirectUrl: _appConfig.default.webBaseUrl,
        culture_info: (0, _translate.getLanguage)(),
        application_scope: _appConfig.default.applicationScope,
        moduleRedirectUrl: location.href
      });

      let url = `${_appConfig.default.oauthUrl}/Authorization/${_appConfig.default.instanceId}/Token?${params}`;

      location.replace(url);
    } else {
      // let the application which embeds this module get a new token
      location.reload();
    }

    return new Ember.RSVP.Promise(() => {}); // never resolve so no error-message gets shown
  }
});