define('kursausschreibung/components/input/input-file', ['exports', 'kursausschreibung/framework/translate', 'kursausschreibung/framework/form-helpers', 'uikit'], function (exports, _translate, _formHelpers, _uikit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getInputFile(fieldId) {
    let elementIdFile = getElementIdFile(fieldId);
    return document.getElementById(elementIdFile).files[0];
  }

  function getElementIdFile(fieldId) {
    return 'file' + fieldId;
  }

  exports.default = Ember.Component.extend({
    change() {

      let elementIdFile = getElementIdFile(this.field.id);
      let inputFile = getInputFile(this.field.id);
      inputFile.imgDev = null;
      let maxFileSizeMB = (this.get('field.maxFileSize') / (1024 * 1024)).toFixed(2);

      if (inputFile.size > this.get('field.maxFileSize') && maxFileSizeMB !== '0.00') {
        _uikit.default.modal.alert((0, _translate.getString)('FileSizeTooBig') + maxFileSizeMB + 'MB');
        (0, _formHelpers.removeFile)(elementIdFile);
      } else if (this.get('field.acceptFileType').indexOf(inputFile.type) === -1 || inputFile.type === "") {
        _uikit.default.modal.alert((0, _translate.getString)('FileTypeNotAccept') + this.get('field.acceptFileType'));
        (0, _formHelpers.removeFile)(elementIdFile);
      } else {

        this.set('field.fileTypeLabel', inputFile.name);
        this.set('field.fileObject', inputFile);

        let buttonClass = document.getElementById('fileBt' + this.field.id);
        buttonClass.classList.remove('required');
        let buttonClassDel = document.getElementById('fileBtDel' + this.field.id);
        buttonClassDel.classList.remove('uk-hidden');

        const reader = new FileReader();
        let data;

        // Note: reading file is async
        reader.onload = () => {
          data = reader.result;
          this.set('field.fileObject.data', data);
        };

        if (inputFile) {
          reader.readAsDataURL(inputFile);
        }

        if (this.get('field.acceptFileType') === 'image/jpeg') {

          let fieldId = this.field.id;
          let buttonClassUpload = document.getElementById('fileBtUpload' + fieldId);
          buttonClassUpload.classList.remove('uk-hidden');

          let imgField = document.getElementById('img' + fieldId);
          imgField.classList.remove('uk-hidden');

          var basic = Ember.$('#img' + this.field.id).croppie({
            viewport: { width: 300, height: 400 },
            boundary: { width: 350, height: 450 }
          });

          basic.croppie('bind', {
            url: URL.createObjectURL(inputFile)
          });
        }

        _uikit.default.notification({ message: (0, _translate.getString)('UploadErfolgreich') + inputFile.name, pos: 'bottom-right', status: 'success' });

        let field = this.get('field');
        (0, _formHelpers.vssDependency)(inputFile, field);
      }
    },
    actions: {

      deleteFile() {
        let fieldId = this.field.id;
        let elementIdFile = getElementIdFile(fieldId);
        let buttonClassDel = document.getElementById('fileBtDel' + fieldId);
        buttonClassDel.classList.add('uk-hidden');

        if (this.get('field.options.required')) {
          let buttonClass = document.getElementById('fileBt' + this.field.id);
          buttonClass.classList.add('required');
        }

        let imgClassDel = document.getElementById('img' + fieldId);
        imgClassDel.classList.add('uk-hidden');
        let imgClassUp = document.getElementById('fileBtUpload' + fieldId);
        imgClassUp.classList.add('uk-hidden');
        let imgFielDev = document.getElementById('imgDev' + fieldId);
        imgFielDev.classList.add('uk-hidden');
        (0, _formHelpers.removeFile)(elementIdFile);
        this.set('field.fileTypeLabel', this.get('field.fileLabelBevorFileChoose'));

        Ember.$('#img' + this.field.id).croppie('destroy');
      },
      uploadImage() {
        let fieldId = this.field.id;
        //on button click
        let basic = Ember.$('#img' + fieldId);
        let inputFile = getInputFile(fieldId);
        basic.croppie('result', {
          type: 'base64',
          format: 'jpeg',
          size: { width: '300', height: '400' }
        }).then(function (base64) {
          // html is div (overflow hidden)
          // with img positioned inside.
          inputFile.imgDev = base64;

          let imgFielDev = document.getElementById('imgDev' + fieldId);
          imgFielDev.src = base64;
          imgFielDev.classList.remove('uk-hidden');
          let imgClassUp = document.getElementById('fileBtUpload' + fieldId);
          imgClassUp.classList.add('uk-hidden');
          let imgClassDel = document.getElementById('img' + fieldId);
          imgClassDel.classList.add('uk-hidden');

          Ember.$('#img' + fieldId).croppie('destroy');
        });
      }

    }

  });
});