define('kursausschreibung/components/input/input-dropdown', ['exports', 'kursausschreibung/framework/form-helpers'], function (exports, _formHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        change() {
            let field = this.get('field');
            let currentValue = null;

            document.getElementsByName(field.id).forEach(input => {
                if (field.options.showAsRadioButtons) {
                    currentValue = input.checked ? input.value : currentValue;
                } else {
                    currentValue = input.value;
                }
            });

            (0, _formHelpers.vssDependency)(currentValue, field);
        }
    });
});