define('kursausschreibung/components/input/input-postal-code', ['exports', 'kursausschreibung/framework/api'], function (exports, _api) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      const fetchPostalCodes = (query, asyncResults) => {
        (0, _api.getPostalCodes)(query).then(response => asyncResults(response));
      };

      let $typeahead = this.$('.typeahead');
      let $locationFields = this.$().closest('fieldset').find('input[name="Location"]');

      $typeahead.typeahead({
        hint: true,
        highlight: true,
        minLength: 2
      }, {
        async: true,
        limit: 10,
        source: (query, _syncResults, asyncResults) => {
          Ember.run.debounce(null, fetchPostalCodes, query, asyncResults, 200);
        },
        displayKey: 'Code',
        templates: {
          suggestion: object => `<div>${object.Code} ${object.Location}</div>`
        }
      });

      $typeahead.on('typeahead:select', (_event, suggestion) => $locationFields.val(suggestion.Location));
    },

    willDestroyElement() {
      this.$('.typeahead').typeahead('destroy');
      this._super(...arguments);
    }
  });
});