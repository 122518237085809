define('kursausschreibung/routes/permalink', ['exports', 'kursausschreibung/framework/store'], function (exports, _store) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model(params) {
      const event = (0, _store.getEventById)(params.event_id);

      // redirect to event if it exists
      if (event !== undefined) {
        this.replaceWith('list.category.event', event.areaKey, event.categoryKey, event.Id);
      } else {
        this.replaceWith('');
      }
    }
  });
});