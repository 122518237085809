define('kursausschreibung/components/list-pagination', ['exports', 'kursausschreibung/framework/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // pages to show before and after the current page
  let n = 2;

  exports.default = Ember.Component.extend({
    lastPage: Ember.computed('items', function () {
      let filter = this.get('items').filter(item => item.codes instanceof Array);
      return filter.length > 0 ? 1 : Math.ceil(this.get('items').length / _settings.default.itemsPerPage);
    }),

    isFirstPage: Ember.computed('page', function () {
      return this.get('page') === 1;
    }),

    isLastPage: Ember.computed('page', 'lastPage', function () {
      return this.get('page') === this.get('lastPage');
    }),

    nextPage: Ember.computed('page', function () {
      return this.get('page') + 1;
    }),

    previousPage: Ember.computed('page', function () {
      return this.get('page') - 1;
    }),

    showFirst: Ember.computed('page', function () {
      return this.get('page') > 1 + n;
    }),

    showLast: Ember.computed('page', 'lastPage', function () {
      return this.get('page') < this.get('lastPage') - n;
    }),

    showLeftDots: Ember.computed('page', function () {
      return this.get('page') > n + 2;
    }),

    showRightDots: Ember.computed('page', 'lastPage', function () {
      return this.get('page') < this.get('lastPage') - (n + 1);
    }),

    pages: Ember.computed('page', 'lastPage', function () {
      let page = this.get('page');
      let lastPage = this.get('lastPage');

      let min = page - n >= 1 ? page - n : 1;
      let max = page + n <= lastPage ? page + n : lastPage;

      let pages = [];

      for (let i = min; i <= max; i++) {
        pages.push({ page: i, active: i === page });
      }

      return pages;
    }),

    itemsOnCurrentPage: Ember.computed('items', 'page', function () {
      let page = this.get('page');
      let filter = this.get('items').filter(item => item.codes instanceof Array);
      return filter.length > 0 ? this.get('items') : this.get('items').slice(_settings.default.itemsPerPage * (page - 1), _settings.default.itemsPerPage * page);
    }),

    filterCodes: Ember.computed('items', function () {

      let filterCodes = this.get('itemsOnCurrentPage').filter(item => item.allfilterCodes instanceof Array);
      let eventfilterCodes = [];
      filterCodes.forEach(event => {

        let existsFilter = filterCodes[0].allfilterCodes.filter(filter => event.filter.indexOf(filter.id) > -1);

        existsFilter.map(filter => {
          if (eventfilterCodes.includes(filter) === false) {
            eventfilterCodes.push(filter);
          }
        });
      });

      return eventfilterCodes.length === 1 ? null : eventfilterCodes;
    })
  });
});