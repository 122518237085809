define('kursausschreibung/routes/list/category', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model(params) {
      let categories = this.modelFor('list').categories;

      // make sure old URLs still work
      params.category = Ember.String.underscore(params.category);

      // check if category exists
      if (!categories.hasOwnProperty(params.category)) {
        this.replaceWith('list');
        return;
      }

      return categories[params.category];
    }
  });
});