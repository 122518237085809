define('kursausschreibung/components/status-lamp', ['exports', 'kursausschreibung/framework/translate'], function (exports, _translate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const statuses = {
    green: { tooltip: (0, _translate.getString)('greenLamp'), className: 'lamp-green', icon: 'pencil' },
    chartreuse: { tooltip: (0, _translate.getString)('chartreuseLamp'), className: 'lamp-chartreuse', icon: 'check' },
    yellow: { tooltip: (0, _translate.getString)('yellowLamp'), className: 'lamp-yellow', icon: 'clock' },
    red: { tooltip: (0, _translate.getString)('redLamp'), className: 'lamp-red', icon: 'close' },
    orange: { tooltip: (0, _translate.getString)('orangeLamp'), className: 'lamp-orange', icon: 'ban' }
  };

  exports.default = Ember.Component.extend({
    init() {
      this._super(...arguments);
      // trigger observer
      this.statusChanged();
    },

    statusChanged: Ember.observer('status', function () {
      let status = statuses[this.get('status')];

      if (status !== undefined) {
        this.set('tooltip', status.tooltip);
        this.set('color', status.className);
        this.set('icon', status.icon);
      }
    }),

    tagName: 'span',
    attributeBindings: ['tooltip:data-uk-tooltip', "icon:uk-icon"],
    classNames: ['status-lamp', 'icon-lamp'],
    classNameBindings: ['color']
  });
});