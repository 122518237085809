define('kursausschreibung/components/input/input-string', ['exports', 'kursausschreibung/framework/form-helpers'], function (exports, _formHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    change() {
      if (this.field.id === 'SocialSecurityNumber') {
        (0, _formHelpers.helperSocialSecurityNumber)(this.element.children[0]);
      }
    },
    keyUp() {
      this.change();
    },
    focusOut() {
      let field = this.get('field');
      let currentValue = document.getElementById('vss' + field.id).value;
      (0, _formHelpers.vssDependency)(currentValue, field);
    }
  });
});