define('kursausschreibung/framework/api', ['exports', 'kursausschreibung/framework/app-config', 'kursausschreibung/framework/storage'], function (exports, _appConfig, _storage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.SUBSCRIPTION_DETAIL_ALLOW_MULTIPLE_PEOPLE = undefined;
  exports.getUserSettings = getUserSettings;
  exports.getEvents = getEvents;
  exports.getEvent = getEvent;
  exports.getLessons = getLessons;
  exports.getEventLocations = getEventLocations;
  exports.getEventCodes = getEventCodes;
  exports.getSubscriptionDetails = getSubscriptionDetails;
  exports.getSubscriptionDetailDependencies = getSubscriptionDetailDependencies;
  exports.getEventTexts = getEventTexts;
  exports.getDropDownItems = getDropDownItems;
  exports.getPostalCodes = getPostalCodes;
  exports.postPerson = postPerson;
  exports.putPerson = putPerson;
  exports.postAddress = postAddress;
  exports.postSubscription = postSubscription;
  exports.postSubscriptionDetailsFiles = postSubscriptionDetailsFiles;


  let accessToken = null;

  /**
   * do a call to the API-server
   * @param {string} method the HTTP method for the call
   * @param {string} relativeUrl the URL relative to the apiUrl
   * @param {boolean} readableError pass false to get the initial exception
   * @param {object} data data for POST and PUT calls
   * @param {boolean} file for file upload change data and contentType
   */
  function ajax(method, relativeUrl, readableError = true, data = null, file = false) {
    if (accessToken === null) accessToken = (0, _storage.getAccessToken)();

    if (file === false) {
      data = data !== null ? JSON.stringify(data, null, '\t') : undefined;
    }

    let promise = Ember.$.ajax({
      method: method,
      dataType: 'json',
      contentType: method === 'GET' ? 'text/javascript' : file ? false : 'application/json',
      processData: false,
      data: data,
      url: _appConfig.default.apiUrl + '/' + relativeUrl,

      // convert empty response to valid JSON
      dataFilter: data => data === '' ? 'null' : data,

      headers: {
        'CLX-Authorization': `token_type=${_appConfig.default.tokenType}, access_token=${accessToken}`
      }
    });

    if (readableError) {
      promise = promise.catch(() => {
        throw new Error(`${method}-request to ${relativeUrl} failed`); // human-readable error
      });
    }

    return promise;
  }

  function post(relativeUrl, data) {
    return ajax('POST', relativeUrl, false, data);
  }

  function put(relativeUrl, data, file = false) {
    return ajax('PUT', relativeUrl, false, data, file);
  }

  function get(relativeUrl, readableError) {
    return ajax('GET', relativeUrl, readableError);
  }

  /**
   * get UserSettings
   */
  function getUserSettings() {
    return get('UserSettings/');
  }

  /**
   * get all events
   */
  function getEvents() {
    return get('Events/');
  }

  /**
   * get an Event
   * @param {number} eventId the id of the event
   */
  function getEvent(eventId) {
    return get('Events/' + eventId);
  }

  /**
   * get the lessons for all events
   */
  function getLessons() {
    return get('Lessons/');
  }

  /**
   * get the locations for all events
   */
  function getEventLocations() {
    return get('EventLocations/');
  }

  /**
   * get codes that are aasigned events
   */
  function getEventCodes() {
    return get('EventCodes/');
  }

  /**
   * this subscription detail specifies if multiple people can
   * subscribe at the same time
   */
  const SUBSCRIPTION_DETAIL_ALLOW_MULTIPLE_PEOPLE = exports.SUBSCRIPTION_DETAIL_ALLOW_MULTIPLE_PEOPLE = 10893;

  /**
   * get subscriptionDetails of an event
   * @param {number} eventId the id of the event
   */
  function getSubscriptionDetails(eventId) {
    return get('Events/' + eventId + '/SubscriptionDetails');
  }

  /**
   * get subscriptionDetailDependencies of an event
   * @param {number} eventId the id of the event
   */
  function getSubscriptionDetailDependencies(eventId) {
    return get('SubscriptionDetailDependencies/?idEvent=' + eventId);
  }

  /**
   * get all eventTexts
   * @param {string} cultureInfo 'de-CH' for german and 'en-US' for french
   */
  function getEventTexts(cultureInfo) {
    return get('EventTexts/?CultureInfo=' + cultureInfo);
  }

  let dropDownItems = {};

  /**
   * get available options for dropdown menu
   * @param {string} type type of the items
   */
  function getDropDownItems(type) {
    if (dropDownItems.hasOwnProperty(type)) {
      return Ember.RSVP.Promise.resolve(dropDownItems[type]);
    }

    return get('DropDownItems/' + type).then(response => dropDownItems[type] = response);
  }

  /**
   * search postal codes
   * @param {number} code postal code
   */
  function getPostalCodes(code) {
    return get(`PostalCodes/?filter.Code=~${code}*`);
  }

  /**
   * post a new person
   * @param {object} data data of the person
   */
  function postPerson(data) {
    return post('Persons/', data);
  }

  /**
   * update an existing person
   * @param {object} data data of the person
   * @param {number} personId id of the person
   */
  function putPerson(data, personId) {
    return put('Persons/' + personId, data);
  }

  /**
   * post a new address
   * @param {object} data data of the address
   */
  function postAddress(data) {
    return post('Addresses/', data);
  }

  /**
   * post a new subscription
   * @param {object} data data of the subscription
   */
  function postSubscription(data) {
    return post('Subscriptions/', data);
  }

  /**
   * Post Files to SubscriptionDetails
   * @param {object} data of the subscription files
   * @param {image} image des files Base64Codierung
   * @returns 
   */
  function postSubscriptionDetailsFiles(data, file) {
    return new Ember.RSVP.Promise(resolve => post('SubscriptionDetails/files', data).then((_data, _status, xhr) => {
      resolve([xhr]);
    })).then(([xhr]) => {
      // xhr is in an array so it gets correctly passed along
      let locationHeader = xhr.getResponseHeader('location');
      let arrayBuffer = base64ToArrayBuffer(file.fileAsBase64.substring(file.fileAsBase64.indexOf('base64,') + 7, file.fileAsBase64.length));
      return put(locationHeader, arrayBuffer, true);
    });
  }
  /**
   * https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer 
   */
  function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
});