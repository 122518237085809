define('kursausschreibung/components/event-list-item', ['exports', 'kursausschreibung/framework/settings', 'kursausschreibung/framework/translate'], function (exports, _settings, _translate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: 'jsfilter',

    title: _settings.default.eventListTitle,

    fields: _settings.default.eventListFields.map(key => ({
      name: (0, _translate.getString)(Ember.String.camelize(key)), key
    }))
  });
});