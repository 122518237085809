define("kursausschreibung/framework/ics-file", ["module", "exports", "kursausschreibung/framework/date-helpers"], function (module, exports, _dateHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.getIcsFileFromEvent = getIcsFileFromEvent;


    /* eslint-disable */
    function getIcsFileFromEvent(event) {

        /*! ics.js Wed Aug 20 2014 17:23:02 
        * https://github.com/nwcell/ics.js
        * LIB property TRANSP to TRANSP:OPAQUE
        * ics.js lib on mobil Browser does not work #40
        * Android ignoriert die locale Zeitzone (ohne Z am ende) in einer ics Datei #44
        */
        var saveAs = saveAs || function (e) {
            "use strict";
            if (typeof e === "undefined" || typeof navigator !== "undefined" && /MSIE [1-9]\./.test(navigator.userAgent)) {
                return;
            }var t = e.document,
                n = function () {
                return e.URL || e.webkitURL || e;
            },
                r = t.createElementNS("http://www.w3.org/1999/xhtml", "a"),
                o = "download" in r,
                a = function (e) {
                var t = new MouseEvent("click");e.dispatchEvent(t);
            },
                i = /constructor/i.test(e.HTMLElement) || e.safari,
                f = /CriOS\/[\d]+/.test(navigator.userAgent),
                u = function (t) {
                (e.setImmediate || e.setTimeout)(function () {
                    throw t;
                }, 0);
            },
                s = "application/octet-stream",
                d = 1e3 * 40,
                c = function (e) {
                var t = function () {
                    if (typeof e === "string") {
                        n().revokeObjectURL(e);
                    } else {
                        e.remove();
                    }
                };setTimeout(t, d);
            },
                l = function (e, t, n) {
                t = [].concat(t);var r = t.length;while (r--) {
                    var o = e["on" + t[r]];if (typeof o === "function") {
                        try {
                            o.call(e, n || e);
                        } catch (a) {
                            u(a);
                        }
                    }
                }
            },
                p = function (e) {
                if (/^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(e.type)) {
                    return new Blob([String.fromCharCode(65279), e], { type: e.type });
                }return e;
            },
                v = function (t, u, d) {
                if (!d) {
                    t = p(t);
                }var v = this,
                    w = t.type,
                    m = w === s,
                    y,
                    h = function () {
                    l(v, "writestart progress write writeend".split(" "));
                },
                    S = function () {
                    if ((f || m && i) && e.FileReader) {
                        var r = new FileReader();r.onloadend = function () {
                            var t = f ? r.result : r.result.replace(/^data:[^;]*;/, "data:attachment/file;");var n = e.open(t, "_blank");if (!n) e.location.href = t;t = undefined;v.readyState = v.DONE;h();
                        };r.readAsDataURL(t);v.readyState = v.INIT;return;
                    }if (!y) {
                        y = n().createObjectURL(t);
                    }if (m) {
                        e.location.href = y;
                    } else {
                        var o = e.open(y, "_blank");if (!o) {
                            e.location.href = y;
                        }
                    }v.readyState = v.DONE;h();c(y);
                };v.readyState = v.INIT;if (o) {
                    y = n().createObjectURL(t);setTimeout(function () {
                        r.href = y;r.download = u;a(r);h();c(y);v.readyState = v.DONE;
                    });return;
                }S();
            },
                w = v.prototype,
                m = function (e, t, n) {
                return new v(e, t || e.name || "download", n);
            };if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
                return function (e, t, n) {
                    t = t || e.name || "download";if (!n) {
                        e = p(e);
                    }return navigator.msSaveOrOpenBlob(e, t);
                };
            }w.abort = function () {};w.readyState = w.INIT = 0;w.WRITING = 1;w.DONE = 2;w.error = w.onwritestart = w.onprogress = w.onwrite = w.onabort = w.onerror = w.onwriteend = null;return m;
        }(typeof self !== "undefined" && self || typeof window !== "undefined" && window || this.content);if (typeof module !== "undefined" && module.exports) {
            module.exports.saveAs = saveAs;
        } else if (typeof define !== "undefined" && define !== null && define.amd !== null) {
            define("FileSaver.js", function () {
                return saveAs;
            });
        }
        var ics = function (e, t) {
            "use strict";
            {
                if (!(navigator.userAgent.indexOf("MSIE") > -1 && -1 == navigator.userAgent.indexOf("MSIE 10"))) {
                    void 0 === e && (e = "default"), void 0 === t && (t = "Calendar");var r = -1 !== navigator.appVersion.indexOf("Win") ? "\r\n" : "\n",
                        n = [],
                        i = ["BEGIN:VCALENDAR", "PRODID:" + t, "VERSION:2.0", "BEGIN:VTIMEZONE", "TZID:Europe/Zurich", "BEGIN:DAYLIGHT", "TZOFFSETFROM:+0100", "RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU", "DTSTART:19810329T020000", "TZNAME:MESZ", "TZOFFSETTO:+0200", "END:DAYLIGHT", "BEGIN:STANDARD", "TZOFFSETFROM:+0200", "RRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU", "DTSTART:19961027T030000", "TZNAME:MEZ", "TZOFFSETTO:+0100", "END:STANDARD", "END:VTIMEZONE"].join(r),
                        o = r + "END:VCALENDAR",
                        a = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];return { events: function () {
                            return n;
                        }, calendar: function () {
                            return i + r + n.join(r) + o;
                        }, addEvent: function (t, i, o, l, u, s) {
                            if (void 0 === t || void 0 === i || void 0 === o || void 0 === l || void 0 === u) return !1;if (s && !s.rrule) {
                                if ("YEARLY" !== s.freq && "MONTHLY" !== s.freq && "WEEKLY" !== s.freq && "DAILY" !== s.freq) throw "Recurrence rrule frequency must be provided and be one of the following: 'YEARLY', 'MONTHLY', 'WEEKLY', or 'DAILY'";if (s.until && isNaN(Date.parse(s.until))) throw "Recurrence rrule 'until' must be a valid date string";if (s.interval && isNaN(parseInt(s.interval))) throw "Recurrence rrule 'interval' must be an integer";if (s.count && isNaN(parseInt(s.count))) throw "Recurrence rrule 'count' must be an integer";if (void 0 !== s.byday) {
                                    if ("[object Array]" !== Object.prototype.toString.call(s.byday)) throw "Recurrence rrule 'byday' must be an array";if (s.byday.length > 7) throw "Recurrence rrule 'byday' array must not be longer than the 7 days in a week";s.byday = s.byday.filter(function (e, t) {
                                        return s.byday.indexOf(e) == t;
                                    });for (var c in s.byday) if (a.indexOf(s.byday[c]) < 0) throw "Recurrence rrule 'byday' values must include only the following: 'SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'";
                                }
                            }var g = new Date(l),
                                d = new Date(u),
                                f = new Date(),
                                S = ("0000" + g.getFullYear().toString()).slice(-4),
                                E = ("00" + (g.getMonth() + 1).toString()).slice(-2),
                                v = ("00" + g.getDate().toString()).slice(-2),
                                y = ("00" + g.getHours().toString()).slice(-2),
                                A = ("00" + g.getMinutes().toString()).slice(-2),
                                T = ("00" + g.getSeconds().toString()).slice(-2),
                                b = ("0000" + d.getFullYear().toString()).slice(-4),
                                D = ("00" + (d.getMonth() + 1).toString()).slice(-2),
                                N = ("00" + d.getDate().toString()).slice(-2),
                                h = ("00" + d.getHours().toString()).slice(-2),
                                I = ("00" + d.getMinutes().toString()).slice(-2),
                                R = ("00" + d.getMinutes().toString()).slice(-2),
                                M = ("0000" + f.getFullYear().toString()).slice(-4),
                                w = ("00" + (f.getMonth() + 1).toString()).slice(-2),
                                L = ("00" + f.getDate().toString()).slice(-2),
                                O = ("00" + f.getHours().toString()).slice(-2),
                                p = ("00" + f.getMinutes().toString()).slice(-2),
                                Y = ("00" + f.getMinutes().toString()).slice(-2),
                                U = "",
                                V = "";y + A + T + h + I + R != 0 && (U = "T" + y + A + T, V = "T" + h + I + R);var B,
                                C = S + E + v + U,
                                j = b + D + N + V,
                                m = M + w + L + ("T" + O + p + Y);if (s) if (s.rrule) B = s.rrule;else {
                                if (B = "rrule:FREQ=" + s.freq, s.until) {
                                    var x = new Date(Date.parse(s.until)).toISOString();B += ";UNTIL=" + x.substring(0, x.length - 13).replace(/[-]/g, "") + "000000Z";
                                }s.interval && (B += ";INTERVAL=" + s.interval), s.count && (B += ";COUNT=" + s.count), s.byday && s.byday.length > 0 && (B += ";BYDAY=" + s.byday.join(","));
                            }new Date().toISOString();var H = ["BEGIN:VEVENT", "UID:" + n.length + "@" + e, "CLASS:PUBLIC", "DESCRIPTION:" + i, "DTSTAMP:" + m, "DTSTART;TZID=Europe/Zurich:" + C, "DTEND;TZID=Europe/Zurich:" + j, "LOCATION:" + o, "SUMMARY:" + t, "TRANSP:OPAQUE", "END:VEVENT"];return B && H.splice(4, 0, B), H = H.join(r), n.push(H), H;
                        }, download: function (e, t) {
                            if (n.length < 1) return !1;t = void 0 !== t ? t : ".ics", e = void 0 !== e ? e : "calendar";var a,
                                l = i + r + n.join(r) + o;if (-1 === navigator.userAgent.indexOf("MSIE 10")) a = new Blob([l], { type: "text/calendar" });else {
                                var u = new BlobBuilder();u.append(l), a = u.getBlob("text/x-vCalendar;charset=" + document.characterSet);
                            }return saveAs(a, e + t), l;
                        }, build: function () {
                            return !(n.length < 1) && i + r + n.join(r) + o;
                        } };
                }console.log("Unsupported Browser");
            }
        };
        /*https://github.com/nwcell/ics.js */

        // You can use this for easy debugging
        /*
        console.log(event);
        var makelogs = function(obj) {
            console.log('Events Array');
            console.log('=================');
            console.log(obj.events());
            console.log('Calendar With Header');
            console.log('=================');
            console.log(obj.calendar());
          }
          */
        let eventlocation = event.ResourceDesignation + ', ' + event.BuildingName + ', ' + event.BuildingAddress + ', ' + event.BuildingZip + ' ' + event.BuildingLocation;
        if (event.ResourceDesignation === undefined) {
            eventlocation = !event.Location ? '' : event.Location;
        }

        let cal = ics();
        event.lessons.forEach(lesson => {
            let leadership = !event.Leadership ? '' : ' (' + event.Leadership + ')';
            let lessonDesignation = !lesson.Designation ? '' : lesson.Designation;
            cal.addEvent(event.Designation + leadership.replace(/(\r\n|\n|\r)/gm, ""), lessonDesignation, eventlocation.replace(/(\r\n|\n|\r)/gm, ""), (0, _dateHelpers.getDateTimeForIcs)(lesson.DateTimeFrom), (0, _dateHelpers.getDateTimeForIcs)(lesson.DateTimeTo));
        });

        //makelogs(cal);
        cal.download(event.Number);
    }
});