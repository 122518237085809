define('kursausschreibung/components/event-list-search', ['exports', 'kursausschreibung/framework/url-helpers'], function (exports, _urlHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // tests if a query matches a value
  function match(value, query) {

    if (typeof value === 'object' && value !== null) {
      value = Object.values(value).join('|');
    }

    return typeof value === 'string' && value.toLowerCase().indexOf(query) !== -1;
  }

  exports.default = Ember.Component.extend({
    query: (0, _urlHelpers.getParameterByName)('search'),
    // update the filtered events when the events change
    eventsChanged: Ember.observer('events', function () {
      this.send('queryChanged');
    }),

    willRender() {
      this.send('queryChanged');
    },

    filteredEvents: Ember.computed.oneWay('events'),

    keyUp() {
      (0, _urlHelpers.setParameterByName)('search', this.get('query'));
    },

    actions: {
      clearSearch() {
        this.set('query', '');
        (0, _urlHelpers.setParameterByName)('search', '');
      },

      queryChanged() {
        let query = this.get('query');
        query = query === null ? '' : query.toLowerCase();
        // don't filter the events when the query is empty
        if (query === '') {
          this.set('filteredEvents', this.get('events'));
          return;
        }

        this.set('filteredEvents', this.get('events')
        // search the query string in event-properties and memo-texts
        .filter(event => Object.keys(event).some(key => match(event[key], query)) || event.texts.some(text => match(text.memo, query))));

        this.get('queryChanged')(query);
      }
    }
  });
});