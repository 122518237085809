define('kursausschreibung/components/input-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'uk-width-1-1',

    componentType: Ember.computed('field.dataType', function () {
      let dataType = this.get('field.dataType');

      // provide typeahead functionality for postal codes (see issue #75)
      // change the type of the fields here so there is no need to change
      // any settings
      if (this.get('field.id') === 'Zip') {
        dataType = 'postal-code';
      }

      return 'input/input-' + dataType;
    })
  });
});