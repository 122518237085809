define('kursausschreibung/routes/list', ['exports', 'uikit'], function (exports, _uikit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model(params) {
      let eventsByArea = this.modelFor('application');

      // make sure old URLs still work
      params.area_of_education = Ember.String.underscore(params.area_of_education);

      // check if area of education exists
      if (!eventsByArea.areas.hasOwnProperty(params.area_of_education)) {
        this.replaceWith('/');
        return;
      }

      return eventsByArea.areas[params.area_of_education];
    },

    actions: {
      didTransition() {
        let modal = _uikit.default.modal('#menu-modal');

        if (modal !== undefined) {
          modal.hide();
        }
      }
    }
  });
});