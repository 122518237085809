define('kursausschreibung/framework/storage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getDataToSubmit = getDataToSubmit;
  exports.setDataToSubmit = setDataToSubmit;
  /* loosely based on the CLX framework */

  /**
   * stores an object serialized as JSON
   * @param {string} key key used to store the object
   * @param {object} value the value to store
   */
  function setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * reads and deserializes an object from the localStorage
   * @param {string} key key used to store the object
   */
  function getItem(key) {
    let item = localStorage.getItem(key);

    return item !== undefined ? JSON.parse(item) : null;
  }

  // export getters and setters for all the values
  let [[getCulture, setCulture], [getAccessToken, setAccessToken], [getRefreshToken, setRefreshToken], [getTokenExpire, setTokenExpire]] = ['uiCulture', 'CLX.LoginToken', 'CLX.RefreshToken', 'CLX.TokenExpire', 'kursausschreibung.dataToSubmit'].map(key => [getItem.bind(null, key), setItem.bind(null, key)]);

  exports.getCulture = getCulture;
  exports.setCulture = setCulture;
  exports.getAccessToken = getAccessToken;
  exports.setAccessToken = setAccessToken;
  exports.getRefreshToken = getRefreshToken;
  exports.setRefreshToken = setRefreshToken;
  exports.getTokenExpire = getTokenExpire;
  exports.setTokenExpire = setTokenExpire;
  function getDataToSubmit() {
    return window.kursausschreibung.dataToSubmit;
  }

  function setDataToSubmit(dataToSubmit) {
    window.kursausschreibung.dataToSubmit = dataToSubmit;
  }
});